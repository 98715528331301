import c3 from "../assets/c3.jpg";
import c1 from "../assets/c1.jpg";
import cs1 from "../assets/cs1.jpg";
export const rwImgs = [
  {
    img: c3,
    name: "first workd",
    desc: "this is very amazing work you know!!",
  },
  {
    img: c1,
    name: "first workd",
    desc: "this is very amazing work you know!!",
  },
  {
    img: cs1,
    name: "first workd",
    desc: "this is very amazing work you know!!",
  },
];
