export const testimonials = [
  {
    text: "sourcemonks makes me more productive and gets the job done in a fraction of the time. I'm glad I found sourcemonks.",
    user: "Sophia Rocha",
  },
  {
    text: "sourcemonks is exactly what I've been looking for.",
    user: "Asma Hanson",
  },
  {
    text: "With sourcemonks, we have finally accomplished things that have been waiting forever to get done.",
    user: "Mahir Harrell",
  },
];
